const actions = {
    REMOVE_CONSTANT: 'REMOVE_CONSTANT',
    LOG_OUT: 'LOG_OUT',
    LOGGED_IN:'LOGGED_IN',
    ADD_USER_TYPE:'ADD_USER_TYPE',
    ADD_EMAIL:'ADD_EMAIL',
    ADD_PASSWORD:'ADD_PASSWORD',
    ADD_ID_COM_COMPANY:'ADD_ID_COM_COMPANY',
    ADD_ID_COM_USER_TYPE:'ADD_ID_COM_USER_TYPE',
    ADD_LOGIN_TOKEN:'ADD_LOGIN_TOKEN',
    ADD_USERNAME:'ADD_USERNAME',
    ADD_MANAGER: 'ADD_MANAGER',
    ADD_ID_COM_USER:'ADD_ID_COM_USER',
    CALLOPSE_SIDEBAR:'CALLOPSE_SIDEBAR',
    ADD_ID_COM_COMPANY_NAME:'ADD_ID_COM_COMPANY_NAME',
    _Log_Out : () => ({
            type : actions.LOG_OUT,
            }),
    _Logged_In : () => ({
            type : actions.LOGGED_IN,
            }),
    _Add_Id_Com_User_Type : (id_com_user_type) => ({
            type : actions.ADD_ID_COM_USER_TYPE,
            id_com_user_type:id_com_user_type
            }),
    _Add_Email : (email) => ({
            type : actions.ADD_EMAIL,
            email:email
            }),    
    _Add_Password : (password) => ({
            type : actions.ADD_PASSWORD,
            password:password
           }),
    _Add_Id_Com_Company : (id_com_company) => ({
            type : actions.ADD_ID_COM_COMPANY,
            id_com_company:id_com_company
            }),
     _Add_Id_Com_Company_Name : (id_com_company_name) => ({
            type : actions.ADD_ID_COM_COMPANY_NAME,
            id_com_company_name:id_com_company_name
            }),
    _Add_Id_Com_User : (id_com_user) => ({
            type : actions.ADD_ID_COM_USER,
            id_com_user:id_com_user
            }),
    _Add_Login_Token : (loginToken) => ({
            type : actions.ADD_LOGIN_TOKEN,
            loginToken:loginToken
            }),
    _Add_Username : (username) => ({
            type : actions.ADD_USERNAME,
            username:username
              }),
    _Remove_Constant : () => ({
            type : actions.REMOVE_CONSTANT,
            }),     
    _Add_Id_Com_Manager : (id_com_manager) => ({
            type : actions.ADD_MANAGER,
            id_com_manager:id_com_manager
            }),       
    _Callopse_Sidebar :() => ({
            type:actions.CALLOPSE_SIDEBAR
            })  
  };

export default actions;
