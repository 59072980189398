import React, { Component } from 'react';
import { Result} from 'antd';
import { connect } from 'react-redux';
import mapStateToProps from '../../../redux/mapStateToProps';

 
 
class Restricted extends Component {
  
  constructor(props) {
    super(props);
    this.state = { 
      

    }

  }
  render() {
    return (
        <React.Fragment>

        <Result
    status="warning"
    title="Yetkiniz Bulunmamaktadır. "
    subTitle="Bu uyarının yanlışlıkla geldiğini düşünüyorsanız sistem yöneticinize başvurunuz. "

  />
      </React.Fragment>


          );
  }
}


export default  connect(mapStateToProps)(Restricted);
