import {combineReducers} from 'redux';
// import constants from './config/reducer';
// import ThemeSwitcher from './themeswitcher/reducer';
// import ordercart from './order/reducer';
import config from './config/reducer';
// import modals from './modal/reducer';

const AllReducers =   combineReducers({
    config:config,
    //   ThemeSwitcher:ThemeSwitcher,
    //   ordercart:ordercart,
    //   App:App,
    //   modals:modals
      });

export default AllReducers;

