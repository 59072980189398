import {createStore} from 'redux';
import allReducers from './boot-reducer';

function saveToLocalStorage (state) { 
      try {
            const serializedState = JSON.stringify(state)
            localStorage.setItem('state',serializedState)
      }
      catch (e) { 
            console.log(e);
      }
      
}

function loadFromLocalStorage(){
      try {
            const serializedState =  localStorage.getItem('state');
            if(serializedState===null){return undefined}else{return JSON.parse(serializedState)}
      }
      catch (e) { 
            console.log(e);
            return undefined
      }
}


const persistedState = loadFromLocalStorage();
const store = createStore(allReducers,persistedState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) //

store.subscribe(()=>saveToLocalStorage(store.getState()))

export default store;