import React from 'react';
import { Layout, Menu, Breadcrumb } from 'antd';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const { SubMenu } = Menu;
const { Header, Content, Sider ,Footer} = Layout;
function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="http://mitrotech.net/">
          MitroTech DTC
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const BottomBar = () =>{
  

    return (
        <Footer>{Copyright()}</Footer>
        );
    }
    
    export default (BottomBar);