import React, { lazy, Suspense } from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import Loader from '../utilities/loader/loader';

import {connect} from 'react-redux';
import mapStateToProps from '../redux/mapStateToProps';
import restricted from '../pages/containers/Restricted';
  
const routes = [
    {
      path: 'dashboard',
      user_types:['1','3','4'],
       component: lazy(() => import('../pages/Dashboard'  )),
    },
    {
      path: 'CustomerAdd',
      user_types:['1'],
       component: lazy(() => import('../pages/views/Customer/Customer.add'  )),
    },
    {
      path: 'CustomerList',
      user_types:['1'],
       component: lazy(() => import('../pages/views/Customer/Customer.list'  )),
    },
    {
      path: 'CustomerCard',
      user_types:['1'],
      params:'id',
       component: lazy(() => import('../pages/views/Customer/Customer.card'  )),
    },
    {
      path: 'CompanyList',
      user_types:['1'],
       component: lazy(() => import('../pages/views/Company/Company.list'  )),
    },
    {
      path: 'CompanyCard',
      user_types:['1'],
      params:'id',
       component: lazy(() => import('../pages/views/Company/Company.card')),
    },
    {
      path: 'ProjectList',
      user_types:['1','4'],
       component: lazy(() => import('../pages/views/Project/Project.list'  )),
    },
    {
      path: 'ProjectAdd',
      user_types:['1'],
       component: lazy(() => import('../pages/views/Project/Project.add'  )),
    },
    {
      path: 'ProjectCard',
      user_types:['1','4'],

      params:'id',
       component: lazy(() => import('../pages/views/Project/Project.card')),
    },
    {
      path: 'ProcessList',
      user_types:['1'],
       component: lazy(() => import('../pages/views/Process/Process.list')),
    },
    {
      path: 'ProcessAdd',
      user_types:['1'],
       component: lazy(() => import('../pages/views/Process/Process.add')),
    },
    {
    path: 'ProcessCard',
    user_types:['1'],
    params:'id',
     component: lazy(() => import('../pages/views/Process/Process.card')),
  },
  {
    path: 'TicketList',
    user_types:['1','3'],
    component: lazy(() => import('../pages/views/Ticket/Ticket.list')),
  },
    {
    path: 'TicketCard',
    user_types:['1','3'],
    params:'id',
     component: lazy(() => import('../pages/views/Ticket/Ticket.card')),
  },
  {
    path: 'activitieslist',
    user_types:['1'],
    component: lazy(() => import('../pages/views/Activities/Activites.list')),
  },
  {
    path: 'MyProfile',
    user_types:['1','3','4'],
    component: lazy(() => import('../pages/views/Customer/Customer.myprofile')),
  },

  {
    path: 'supportlist',
    user_types:['1','2','4'],
    component: lazy(() => import('../pages/views/Support/Support.list')),
  },
  {
    path: 'supportadd',
    user_types:['1','2','4'],
    component: lazy(() => import('../pages/views/Support/Support.add')),
  },
  {
    path: 'supportcard',
    user_types:['1','2','4'],
    params:'id',
    component: lazy(() => import('../pages/views/Support/Support.card')),
  },


  
]
 

const DashboardRoutes = ({id_com_user_type}) => { 
    const { url } = useRouteMatch();
 
     return(
        <Suspense fallback={<Loader />}>
            <Switch>
            {routes.map((route, idx) => { 
         
              let user_type_control =route.user_types.includes(id_com_user_type) //route.id_com_user_type.includes(id_com_user_type);

               if(user_type_control) {
                return (
                    <Route   exact={route.exact}  key={idx} path={route.params3? `${url}/${route.path}/:${route.params}/:${route.params2}/:${route.params3}`:route.params2? `${url}/${route.path}/:${route.params}/:${route.params2}`:route.params? `${url}/${route.path}/:${route.params}`:`${url}/${route.path}`}  component={route.component}></Route>
                )}
                else  { return (
                  <Route   exact={route.exact}  key={idx} path={route.params3? `${url}/${route.path}/:${route.params}/:${route.params2}/:${route.params3}`:route.params2? `${url}/${route.path}/:${route.params}/:${route.params2}`:route.params? `${url}/${route.path}/:${route.params}`:`${url}/${route.path}`}  component={restricted}></Route>
                )}

            })}
            </Switch>
        </Suspense>


    )
}

export default connect(mapStateToProps)(DashboardRoutes);
