import { LaptopOutlined, CommentOutlined,NotificationOutlined, ToolOutlined, UserAddOutlined,ContainerOutlined,FileProtectOutlined,ApartmentOutlined,CreditCardOutlined,SettingOutlined} from '@ant-design/icons';


const options = {

  admin:[
  {
    key: 'dashboard',
    label: 'DashBoard',
    leftIcon: <LaptopOutlined/>,
   }, 
  // {
  //   key: 'Message',
  //   label: 'İletişim',
  //   forcustomer:false,
  //   leftIcon: <CommentOutlined/>,
  //   children: [
  //     {
  //       key: 'MessageInbox',
  //       label: 'Mesaj Kutusu',
  //       forcustomer:false,

  //     },
  //   ]
  // },
  {
    key: 'customer',
    label: 'Müşteriler',
 
    leftIcon: <UserAddOutlined />,
    children: [
      {
        key: 'customeradd',
        label: 'Müşteri Ekle ',
       },
      {
        key: 'companylist',
        label: 'Firma Listesi ',
       },
      {
        key: 'customerlist',
        label: 'Müşteri Listesi ',
       },
      {
        key: 'customerkvkk',
        label: 'Müşteri Kvkk ',
       },
      {
        key: 'myprofile',
        label: 'Profilim',
       },
      {
        key: 'activitieslist',
        label: 'Aktiviteler',
 
      },
    ]
  },
  {
    key: 'Support',
    label: 'Destek',
     leftIcon: <ToolOutlined/>,
    children: [
      {
        key: 'SupportList',
        label: 'Talep Görüntüle',
 
      },
      {
        key: 'SupportAdd',
        label: 'Talep Oluştur',
 
      },
    ]
  },
  {
    key: 'Offer',
    label: 'Teklifler',
     leftIcon: <ContainerOutlined/>,
    children: [
      {
        key: 'OfferAdd',
         label: 'Teklif Oluştur',
      },
      {
        key: 'OfferList',
         label: 'Teklif Listesi',
      },
      {
        key: 'MyOfferList',
         label: 'Tekliflerim',
      },
    ]
  },
  {
    key: 'Contracts',
    label: 'Sözleşmeler',
     leftIcon: <FileProtectOutlined/>,
    children: [
      {
        key: 'ContractAdd',
         label: 'Sözleşme Oluştur',
      },
      {
        key: 'ContractList',
         label: 'Sözleşme Listesi',
      },
      {
        key: 'MyContractList',
         label: 'Sözleşmerim',
      },
    ]
  },
  {
    key: 'Projects',
    label: 'Projeler',
     leftIcon: <ApartmentOutlined/>,
    children: [
      {
        key: 'ProjectAdd',
         label: 'Proje Oluştur',
      },
      {
        key: 'ProjectList',
         label: 'Proje Listesi',
      },
      {
        key: 'ProcessList',
         label: 'Süreç Listesi',
      },
      {
        key: 'TicketList',
         label: 'Görev Listesi',
      },
    ]
  },
  {
    key: 'Paymensts',
    label: 'Ödemeler',
     leftIcon: <CreditCardOutlined/>,
    children: [
      {
        key: 'PaymenstsAdd',
         label: 'Fatura Oluştur',
      },
      {
        key: 'PaymenstsList',
         label: 'Fatura Listesi',
      },
      {
        key: 'PaymenstsApproval',
         label: 'Fatura Onay Listesi',
      },
      {
        key: 'PaymenstsCustomerList',
         label: 'Faturalarım',
      },
      {
        key: 'PaymentTransactionList',
         label: 'Cari Hareketler',
      },
      {
        key: 'MyPaymentTransactionList',
         label: 'Cari Hesabım',
      },
    ]
  },
  // {
  //   key: 'Settings',
  //   label: 'Tanımlamalar',
  //   forcustomer:false,
  //   leftIcon: <SettingOutlined/>,
  //   children: [
  //     {
  //       key: 'PaymenstsAdd',
  //       forcustomer:false,
  //       label: 'Fatura Oluştur',
  //     },
  //     {
  //       key: 'PaymenstsList',
  //       forcustomer:false,
  //       label: 'Fatura Listesi',
  //     },
  //   ]
  // }
  ],
  customer: [
    {
      key: 'dashboard',
      label: 'DashBoard',
      leftIcon: <LaptopOutlined/>,
     }, 
    {
      key: 'customer',
      label: 'Müşteriler',
 
      leftIcon: <UserAddOutlined />,
      children: [
        
        {
          key: 'customerkvkk',
          label: 'Müşteri Kvkk ',
         },
        {
          key: 'myprofile',
          label: 'Profilim',
         },
      ]
    },
    {
      key: 'Support',
      label: 'Destek',
       leftIcon: <ToolOutlined/>,
      children: [
        {
          key: 'SupportList',
          label: 'Talep Görüntüle',
   
        },
        {
          key: 'SupportAdd',
          label: 'Talep Oluştur',
   
        },
      ]
    },
    {
      key: 'Offer',
      label: 'Teklifler',
       leftIcon: <ContainerOutlined/>,
      children: [
        {
          key: 'OfferAdd',
           label: 'Teklif Oluştur',
        },
        {
          key: 'OfferList',
           label: 'Tekliflerim',
        },
      ]
    },
    {
      key: 'Contracts',
      label: 'Sözleşmeler',
       leftIcon: <FileProtectOutlined/>,
      children: [

        {
          key: 'ContractList',
           label: 'Sözleşmelerim',
        },
      ]
    },
    {
      key: 'Projects',
      label: 'Projeler',
       leftIcon: <ApartmentOutlined/>,
      children: [
        {
          key: 'ProjectList',
           label: 'Projelerim',
        },
      ]
    },
    {
      key: 'Paymensts',
      label: 'Ödemeler',
       leftIcon: <CreditCardOutlined/>,
      children: [
        {
          key: 'PaymenstsCustomerList',
           label: 'Faturalarım',
        },
        {
          key: 'MyPaymentTransactionList',
           label: 'Cari Hesabım',
        },
      ]
    },
  ]
};
export default options;
