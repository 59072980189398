import actions from './actions';

const initialState = {  
    api_url : 'https://customer.mitrodev.app/api',
    api_media : 'https://customer.mitrodev.app/media',
    isLoggedIn:false,
    id_com_user :'',
    id_com_company:'',
    loginToken:'',
    id_com_user_type:'',
    email:'',
    password:'',
    username:'',
    id_com_manager:'',
    id_com_department:'',
    id_com_company_name:'',
    themeName:'defaultTheme',
    callopseSidebar:false,
} 

export default function config  (state = initialState,action)  { 
    switch(action.type) {
        
          
        case actions.LOG_OUT:  
          window.location.href = '/';
          return  state=initialState;

         case actions.ADD_LOGIN_TOKEN:  
         return  {...state, loginToken:action.loginToken}
         
         case actions.LOGGED_IN:  
         return  {...state, isLoggedIn:true}

         case actions.ADD_ID_COM_USER:  
         return  {...state, id_com_user:action.id_com_user}
         
         case actions.ADD_EMAIL:  
         return  {...state, email:action.email}
         
         case actions.ADD_PASSWORD:  
         return  {...state, password:action.password}

         case actions.ADD_ID_COM_COMPANY:  
         return  {...state, id_com_company:action.id_com_company}  

         case actions.ADD_ID_COM_COMPANY_NAME:  
         return  {...state, id_com_company_name:action.id_com_company_name} 

         case actions.ADD_USERNAME:  
         return  {...state, username:action.username}  

         case actions.ADD_ID_COM_USER_TYPE:  
         return  {...state, id_com_user_type:action.id_com_user_type}

         case actions.ADD_MANAGER:  
         return  {...state, id_com_manager:action.id_com_manager}  

         case actions.CALLOPSE_SIDEBAR:  
         return  {...state, callopseSidebar:!state.callopseSidebar} 

         default:
               return state
    }
}

;
