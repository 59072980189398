import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect, 
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PUBLIC_ROUTE } from './Route.constants';
import Loader from '../utilities/loader/loader';
import Layout  from '../Layout/Layout.main';

// const Layout = lazy(() => import('../layout/Layout.main'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('../pages/signing/Sign.in')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    exact: true,
    component: lazy(() => import('./../pages/containers/404/index')),
  },
//   {
//     path: PUBLIC_ROUTE.PAGE_500, 
//     component: lazy(() => import('@iso/containers/Pages/500/500')),
//   },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    exact: true,
    component: lazy(() => import('../pages/signing/Sign.in')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    exact: true,
    component: lazy(() => import('../pages/signing/Sign.up')),
  },
//   {
//     path: PUBLIC_ROUTE.FORGET_PASSWORD,
//     component: lazy(() =>
//       import('@iso/containers/Pages/ForgotPassword/ForgotPassword')
//     ),
//   },
//   {
//     path: PUBLIC_ROUTE.RESET_PASSWORD,
//     component: lazy(() =>
//       import('@iso/containers/Pages/ResetPassword/ResetPassword')
//     ),
//   }
];

function PrivateRoute({ children, ...rest }) {
  const isLoggedIn =  useSelector(state => state.config.isLoggedIn);
     return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const  Routes = ()=>{
  return (
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact}>
                    <route.component />
                </Route>
            ))}
                <PrivateRoute path="/portal">
                    <Layout /> 
                </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
  );
}
export default Routes;