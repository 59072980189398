import React from 'react';
import { Layout, Menu, } from 'antd';
import { Link ,useRouteMatch} from 'react-router-dom';
import { connect ,useDispatch} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import Logo1 from '../../asset/image/hero-icon.svg';
import Logo2 from  '../../asset/image/logo.svg';
import configActions from '../../redux/config/actions';
import sidebar_list from './options';

const {
_Callopse_Sidebar,
} = configActions;

const stripTrailingSlash = str => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};

const { SubMenu } = Menu;
const { Sider } = Layout;

const SideBar = ({callopseSidebar,id_com_user_type}) => { 


  const options = id_com_user_type==='4'?sidebar_list.customer:sidebar_list.admin
  const rootSubmenuKeys =[];
  options.map(submenukeys=>rootSubmenuKeys.push(submenukeys.key))

  const [openKeys, setOpenKeys] = React.useState(['']);

  const onOpenChange = keys => {
     const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };



let match = useRouteMatch();
const url = stripTrailingSlash(match.url);
   const dispatch = useDispatch();
   return (
      <Sider width={200} className="site-layout-background" 
        breakpoint='md'
        onBreakpoint={(broken)=> broken&&!callopseSidebar?  dispatch(_Callopse_Sidebar()):!broken?dispatch(_Callopse_Sidebar()):null}
        collapsedWidth={80}
        collapsed={callopseSidebar}
        collapsible
        theme='light'
        trigger={null} 
      
      >
           <div className="logo" style={{padding:10,alignItems:'center',justifyContent:'center'}} >
           <Link  to={`${url}/dashboard`}>
           <img src={callopseSidebar?Logo1:Logo2} width='100%' height={30} alt='logo'></img>
           </Link>
           </div>
        <Menu openKeys={openKeys} onOpenChange={onOpenChange}
          mode="inline"
          defaultSelectedKeys={['1']}
          style={{ height: '100%', borderRight: 0,paddingTop:20 }}
        >

          {options.map((menu,index)=>{
            const  {label,key,children,leftIcon} = menu
           
  if (children) {

           return( 
            
              <SubMenu key={key} icon={leftIcon} title={label} >
                     {children.map((menuItem,menuIndex)=>{
                    
                    return (
            
                      <Menu.Item key={menuItem.label+menuIndex}>
                          <Link   to={`${url}/${menuItem.key}`}>
                            {menuItem.label}
                          </Link>
                      </Menu.Item>
                 
                    )

                  })}
              </SubMenu>
         


          )}
        else {

          return (
    
            <Menu.Item key={label+index} icon={ leftIcon} >
                <Link   to={`${url}/${key}`}>
                  {label}
                </Link>
            </Menu.Item> 
   
            )
            
            }
          })}

        </Menu>
      </Sider>
   )
}

export default connect(mapStateToProps)(SideBar);


// <SubMenu key="sub1" icon={<UserOutlined />} title="subnav 1">
// <Menu.Item key="1">option1</Menu.Item>
// <Menu.Item key="2">option2</Menu.Item>
// <Menu.Item key="3">option3</Menu.Item>
// <Menu.Item key="4">option4</Menu.Item>
// </SubMenu>
// <SubMenu key="sub2" icon={<LaptopOutlined />} title="subnav 2">
// <Menu.Item key="5">option5</Menu.Item>
// <Menu.Item key="6">option6</Menu.Item>
// <Menu.Item key="7">option7</Menu.Item>
// <Menu.Item key="8">option8</Menu.Item>
// </SubMenu>
// <SubMenu key="sub3" icon={<NotificationOutlined />} title="subnav 3">
// <Menu.Item key="9">option9</Menu.Item>
// <Menu.Item key="10">option10</Menu.Item>
// <Menu.Item key="11">option11</Menu.Item>
// <Menu.Item key="12">option12</Menu.Item>
// </SubMenu>