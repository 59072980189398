
const mapStateToProps = (state ) => {

    const { config } = state
    return {

        id_com_company: config.id_com_company,
        id_com_company_name:config.id_com_company_name,
        id_com_user:  config.id_com_user,
        api_url:  config.api_url,
        api_media: config.api_media,
        username: config.username,
        company_name:config.company_name,
        id_com_user_type:  config.id_com_user_type,
        loginToken: config.loginToken,
        expandAll:config.expandAll,
        callopseSidebar : config.callopseSidebar
    }
}

export default mapStateToProps;