
import React from 'react'; 
import { Layout,Row, Typography } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined ,LogoutOutlined} from '@ant-design/icons';
import { connect,useDispatch } from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import configActions from '../../redux/config/actions';

const {
_Callopse_Sidebar,
_Log_Out
} = configActions;
const {Title} = Typography;
const { Header } = Layout;

const  TopBar = ({callopseSidebar}) => {
 const dispatch = useDispatch();

     return (
      <Header className="header" >
      <Row justify='space-between' align={'middle'} style={{height:'100%'}}>
        {React.createElement(callopseSidebar ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                style:{color:'white',fontSize:24},
                onClick: ()=> dispatch(_Callopse_Sidebar()),
                })}
                <Title style={{color:'white'}} level={3}>
                  MitroTech Proje Yönetim Portalı
                </Title>
        {React.createElement( LogoutOutlined  , {
            className: 'trigger',
            style:{color:'white',fontSize:24},
            onClick: ()=> dispatch(_Log_Out()),
        })}

      </Row>
  

      </Header>
    );
}
    
 
export default connect(mapStateToProps)(TopBar)