import React from 'react';
import { connect,useSelector, useDispatch } from 'react-redux';
import mapStateToProps from '../redux/mapStateToProps';
import DashboardRoutes from '../router/Route.dashboard';
import TopBar from './AppBar';
 
import SideBar from './SideBar';
import BottomBar from './Footer';
import { Layout, Menu, Breadcrumb } from 'antd';
import useWindowSize from './Hooks/WindowSize';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const Contents = () =>{
const { width, height } = useWindowSize();
     return (
  
        <Layout style={{height:height}} >
                        <SideBar/>

            <Layout>
            <TopBar/>
            <Layout style={{ padding: '0 24px 24px' ,minHeight:'auto'}}>

                <Content
                className="site-layout-background"
                style={{
                padding: 24,
                margin: '16px 0',
                background:'white',
                minHeight: 'auto',
                }}
                >
                <DashboardRoutes />
                </Content>
            </Layout>
                     <BottomBar/> 
             </Layout>
        </Layout>
 
    );

}

export default connect(mapStateToProps)(Contents);